// dashboard
import Ecommerce from "pages/Dashboards/Ecommerce";

// HR Management
import EmployeeList from "pages/Admin/Users/Account/EmployeeList";
import Holidays from "pages/HRManagement/Holidays";
import Departments from "pages/Admin/Departments";
import Roles from "pages/Admin/roles";
import UserCategories from "pages/Admin/userCategories";
import Positions from "pages/Admin/positions";

import AppraisalCategories from "pages/HRManagement/Appraisals/appraisal-categories";
import AppraisalRequest from "pages/HRManagement/Appraisals/appraisal-request";
import AppraisalDetails from "pages/HRManagement/Appraisals/appraisal-details";
import AppraisalKpis from "pages/HRManagement/Appraisals/appraisal-kpis";
import AppraisalAdmin from "pages/HRManagement/Appraisals/appraisal-admin";
import Appraisals from "pages/HRManagement/Appraisals/appraisals";

import QRGenerator from "pages/HRManagement/QRGenerator";

// Task Manager
import TaskManeger from "pages/HRManagement/TastManager/TaskManager";

// Leave Management
import LeaveManageEmployee from "pages/HRManagement/Leaves Manage/LeaveManageEmployee";
import AddLeaveEmployee from "pages/HRManagement/Leaves Manage/AddLeaveEmployee";
import LeaveManageHR from "pages/HRManagement/Leaves Manage/LeaveManageHR";
import AddLeaveHR from "pages/HRManagement/Leaves Manage/AddLeaveHR";

//WFH
import WFHBoard from "pages/HRManagement/wfh/wfhBoard";
import WFHRequest from "pages/HRManagement/wfh/wfhRequest";

// Users
import CreateUser from "pages/Admin/Users/Registration/FormWizard";
import UserListView from "pages/Admin/Users/ListView";
import UserGridView from "pages/Admin/Users/GridView";
import EmployeeOverview from "pages/Admin/Users/Account/employeeOverview";
import Account from "pages/Admin/Users/Account";

// Create Password
import Login from "Auth/Authentication/Login";
import Logout from "Auth/Authentication/LogOut";
import Register from "Auth/Authentication/Register";
import UserProfile from "Auth/Authentication/UserProfile";
import LeaveReport from "pages/HRManagement/Leaves Manage/LeaveReport";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: "/", component: Ecommerce },
  { path: "/dashboard", component: Ecommerce },

  
  // HR Management
  { path: "/apps-hr-employee", component: EmployeeList },
  { path: "/apps-hr-holidays", component: Holidays },
  { path: "/apps-hr-department", component: Departments },
  { path: "/leave-report", component: LeaveReport },
  { path: "/qr-generator", component: QRGenerator },
  //admin Management
  { path: "/apps-admin-roles", component: Roles },
  { path: "/apps-admin-userCategories", component: UserCategories },
  { path: "/apps-admin-positions", component: Positions },

  // Users
  { path: "/apps-create-user", component: CreateUser },
  { path: "/apps-users-list", component: UserListView },
  { path: "/apps-users-grid", component: UserGridView },
  { path: "/apps-users-overview/:id", component: EmployeeOverview },

  // profile
  { path: "/user-profile", component: UserProfile },

  { path: "/user-account/:id", component: Account },
  // { path: "/pages-account-settings", component: Settings },
  // { path: "/pages-pricing", component: Pricing },
  // { path: "/pages-faqs", component: Faqs },
  // { path: "/pages-contact-us", component: ContactUs },

  //Appraisal Category
  { path: "/appraisal-categories", component: AppraisalCategories },
  { path: "/appraisals-request", component: AppraisalRequest },
  { path: "/appraisals-report", component: AppraisalAdmin },
  { path: "/appraisals", component: Appraisals },
  { path: "/appaisals/:id", component: AppraisalDetails },
  { path: "/appraisal-kpis", component: AppraisalKpis },

  //WFH
  { path: "/wfh-board", component: WFHBoard },
  { path: "/wfh-request", component: WFHRequest },

  // Leave Management
  { path: "/leave-applications", component: LeaveManageEmployee },
  { path: "/employee-create-leave", component: AddLeaveEmployee },
  { path: "/leave-request", component: LeaveManageHR },
  { path: "/apps-hr-create-leave", component: AddLeaveHR },

  // Task Manager
  { path: "/task-manager", component: TaskManeger },
];

const publicRoutes = [
  // authentication
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
