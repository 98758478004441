import React, { useState, useRef } from 'react';
import { QRCodeCanvas } from "qrcode.react";

const QRGenerator = () => {
    const [text, setText] = useState("");
    const qrRef = useRef<HTMLDivElement | null>(null); // Explicitly typing ref

    const downloadQRCode = () => {
        if (qrRef.current) {
            const canvas = qrRef.current.querySelector("canvas") as HTMLCanvasElement | null;
            if (canvas) {
                const url = canvas.toDataURL("image/png");
                const link = document.createElement("a");
                link.href = url;
                link.download = "qr_code.png";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen p-4">
            <h2 className="text-xl font-bold mb-4">Agusto QR Code Generator</h2>
            <input
                type="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="border p-2 rounded mb-4 w-64 text-center"
                placeholder="Enter URL here for QR Code"
            />
            <div ref={qrRef} className="p-4 bg-white rounded shadow-md">
                {text && <QRCodeCanvas value={text} size={200} />}
            </div>
            <button type="button" className="px-2 py-1.5 mt-4 text-xs text-green-500 bg-white border-green-500 btn btn-sm hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100 dark:bg-zink-700 dark:hover:bg-green-500 dark:ring-green-400/20 dark:focus:bg-green-500"
              onClick={downloadQRCode}
              >
                Download
            </button>
        </div>
    );
};

export default QRGenerator;
